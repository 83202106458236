ul {
    list-style-type: none;
    margin-bottom: 40px;
    margin-top: 13px;
    padding: 0;
  }

  .all_textfield_questions_card p {
    display: inline-block;
    margin-top: 0px;
    margin-bottom: 0 !important;
    margin-left: 0;
    margin-right: 0;
  }

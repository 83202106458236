.grade-table.table {
    border-collapse: collapse;
    width: 100%;
  }
  
 .grade-table {
    border: 1px solid #c5c5c5;
    text-align: center;
    padding: 8px;
    font-size: 10px;
  }

  .grade-table .green-bg {
    background-color: #e2efd9;
  }

  .grade-table .blue-bg {
    background-color: #deeaf6;
  }

  .grade-table .peach-bg {
    background-color: #fbe4d5;
  }
  
  .grade-table .grey-bg {
    background-color: #f2f2f2;
  }

  .grade-table .grade-table-header {
    font-weight: bo;
    background-color: #6e6e6e;
    color: white;
  }
  


.css-1qw96cp:last-child {
    padding-bottom: 12px;
}

#standard-basic\ question-field {
    min-width: 500px;
}

#standard-helperText\ shortDesc-field {
    min-width: 300px;
}

.input_explanation {
    margin-top: 15px;
}

.input_shortdesc {
    margin-top: 90px;
}

.css-46bh2p-MuiCardContent-root{
  margin: 0px;
}

.css-46bh2p-MuiCardContent-root:last-child {
  padding-bottom: 14px;
}

  ul {
    list-style-type: none;
    margin-bottom: 40px;
    margin-top: 13px;
    padding: 0;
  }

  .characters p {
    display: inline-block;
    margin-top: 0;
    margin-bottom: 0;
    margin-left: 0;
    margin-right: 0;
  }


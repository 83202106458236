.grade-table.table {
  border-collapse: collapse;
  width: 100%;
}

.grade-table {
  text-align: center;
  font-size: 10px;
}

.out-of-range-cell {
  opacity: 0.5;
}

.current-selected {
  box-shadow: 0 0 4px 2px #9cbe03 inset;
}

.start-cell {
  box-shadow: 0 0 4px 2px #0b52bd inset;
}

.grade-table .green-bg {
  background-color: #e2efd9;
}

.grade-table .blue-bg {
  background-color: hsl(210, 57%, 92%);
}

.grade-table .peach-bg {
  background-color: #fbe4d5;
}

.grade-table .grey-bg {
  background-color: #f2f2f2;
}

.grade-table .grade-table-header {
  font-weight: bold;
  background-color: #6e6e6e;
  color: white;
}